import Seo from 'src/components/seo/Seo'

import DiaDasCriacas from '../../components/Campanha/DiaDasCriacas'

function ColecaoBeachwear2022() {
  return (
    <>
      <Seo title="Campanha | Decathlon" />
      <DiaDasCriacas />
    </>
  )
}

export default ColecaoBeachwear2022
